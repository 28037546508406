<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.headingFont {
  font-size: 20px !important;
  font-weight: bold !important;
}

.fontStyleType2 {
  font-size: 15px;
}

.fontStyleType3 {
  font-size: 12px;
}

.fontWeightBolder {
  font-weight: bolder;
}

.noFontWeight {
  font-weight: normal;
}

.textFieldFont {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: grey !important;
}

.flexDivType1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flexDivType2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.flexDivType3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flexDivColType {
  display: flex;
  flex-direction: column;
}

.menuFont {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.textFieldWidth {
  max-width: 200px;
}

.textFieldFontSize input {
  font-size: 12px !important;
}

.texFieldLabelFont {
  font-size: 12px;
}

.btnFont {
  font-size: 12px;
}

.purpleBtnColor {
  background-color: #942486 !important;
}

.purpleBorderBtn {
  border: 1px solid #942486 !important;
  color: #942486 !important;
}

.custom-table th {
  font-size: 12px;
  font-weight: bold;
}

.tableItemFont {
  font-size: 12px;
}

.v-data-table tbody tr:hover {
  background-color: #f3e5f5 !important;
  transition: 0.5s ease;
}
</style>
