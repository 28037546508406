import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: () => import("@/views/MainPages/LoginPage.vue"),
  },
  {
    path: "/landingpage",
    name: "LandingPage",
    component: () => import("@/views/MainPages/LandingPage.vue"),
    children: [
      {
        path: "/details",
        name: "My Details",
        component: () => import("@/views/ChildPages/MyDetails.vue"),
      },
      {
        path: "/tickets",
        name: "My Tickets",
        component: () => import("@/views/ChildPages/AllTickets.vue"),
      },
      {
        path: "/products",
        name: "My Products",
        component: () => import("@/views/ChildPages/AllProducts.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
