import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    customerData: [],
    allCustomerData: [],
    loggedInOutRefreshCount: 0,
  },
  getters: {
    getAllCustomerData: (state) => {
      return state.allCustomerData;
    },
    getCurrentCustomerData: (state) => {
      return state.customerData;
    },
    getLoggedInOutRefreshCount: (state) => {
      return state.loggedInOutRefreshCount;
    },
  },
  mutations: {
    SET_ALL_CUSTOMER_DATA(state, customer_data) {
      state.allCustomerData = customer_data;
    },
    SET_CURRENT_CUSTOMER_DATA(state, customer_data) {
      state.customerData = customer_data;
    },
    LOGGED_IN_OUT_REFRESH_COUNT(state, refresh_count) {
      state.loggedInOutRefreshCount = refresh_count;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
