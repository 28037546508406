import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";

import { Amplify } from "aws-amplify";

loadFonts();

Amplify.configure({
  API: {
    GraphQL: {
      region: "ap-south-1",
      endpoint:
        "https://vxrebrfnsbgzxay4mbwzs35g54.appsync-api.ap-south-1.amazonaws.com/graphql",
      defaultAuthMode: "apiKey",
      apiKey: "da2-jpuion4cgrhzzb5yaj32jfawra",
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.mount("#app");
